// Import polyfill from node_modules
// import 'core-js/modules/es6.set.js'
// import 'core-js/modules/es6.array.find-index.js'
// import 'vendor/swiper-bundle.min.js';
// import 'vendor/particles.min.js';
// import 'vendor/stats.min.js';

import { on, off, throttle, isInViewport } from './modules/_fn';

(function () {
	/* ---------------------------------------- [START] Windows Setting */
	const html = document.documentElement;
	let ww = window.innerWidth;
	let wh = window.innerHeight;
	let ws = 0;
	function getScrollTop(target = window) {
		return (target.pageYOffset || html.scrollTop) - (html.clientTop || 0);
	}
	function getWinSet() {
		ww = window.innerWidth;
		wh = window.innerHeight;
		ws = getScrollTop();
	}
	on(window, 'load', getWinSet);
	on(window, 'resize', throttle(getWinSet, 50, 100));
	/* ---------------------------------------- [END] Windows Setting */

	// ---------------------------------------------------------------
	// Header Fixed

	const $fixedHeaderEl = $('.js-fixed-header');
	const $hd = $('#hd');
	let headerIsFixed = false;

	function detectHeaderFixedPos() {
		ws = getScrollTop();
		let elH = $($fixedHeaderEl).outerHeight();
		// console.log(ws, elH)

		if (ws >= elH) {
			if (headerIsFixed) return false;
			headerIsFixed = true;
			$hd.addClass('is-show');
		} else {
			if (!headerIsFixed) return false;
			headerIsFixed = false;
			$hd.removeClass('is-show');
		}
	}

	on(window, 'scroll', throttle(detectHeaderFixedPos, 50, 100));
	// ---------------------------------------------------------------

	// ---------------------------------------------------------------
	// KV
	let kvSwiper = null;
	let videoArray = {};

	var tag = document.createElement('script');
	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	function yt_init($el, index) {
		var self = $el,
			videoBox = self.children('.video-box'),
			videoId = videoBox.attr('data-video').replace(
				/https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig,
				'https://www.youtube.com/embed/$1'
			).split('/embed/')[1];

		videoBox.children().attr('id', 'kv-video' + index);
		var id = self.children('.video-box').children('.video').attr('id');

		bulidPlayer(id, videoId, index);
	}

	function bulidPlayer(id, videoId, index) {
		let player = new YT.Player(id, {
			videoId: videoId,
			playerVars: {
				mute: 1, //Must do this. otherwise wont autoplay on Chrome.
				autoplay: 1,        // 在讀取時自動播放影片(1)
				controls: 0,        // 隱藏控制播放器顯示暫停／播放按鈕(0)
				modestbranding: 1,  // 隱藏YouTube Logo (1)
				loop: 1,          // 讓影片循環播放(1)(因為不是播放清單無法直接執行Loop)
				disablekb: 1,       // 停止鍵盤操控(1)
				rel: 0,            // 不顯示推薦影片(0)
				showinfo: 0,       // 隱藏影片標題(0)
				fs: 0,              // 隱藏全螢幕按鈕 (1)
				cc_load_policty: 0, // 隱藏字幕(0)
				iv_load_policy: 3,  // 隱藏影片註解(3)
				//autohide: 0,        // 當播放影片時隱藏影片控制列(2015/8/19已棄用，自動判斷)
				playsinline: 1,      // 設置iPhone系列可不全螢幕播放(1)(?待確認)
				playlist: videoId
			},
			events: {
				'onReady': function (e) {
					videoArray[id] = player;
					videoArray[id].mute();
					//強制播放(手機才會自動播放，但僅限於Android)
					videoArray[id].playVideo();
				},
				'onStateChange': function (e) {
					if (e.data === YT.PlayerState.ENDED) {
						videoArray[id].playVideo();
					}
				}
			}
		});
	}

	// var checkYT = setInterval(function () {
	// 	if (YT && YT.loaded) {
	// 		yt_init();
	// 		clearInterval(checkYT);
	// 	}
	// }, 100);

	function buildKvCarousel() {
		const swiperTarget = '#kv-carousel';

		kvSwiper = new Swiper(swiperTarget, {
			loop: true,
			autoplay: {
				delay: 10000,
				disableOnInteraction: false
			},
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 2,
			},
			slidesPerView: 1,
			spaceBetween: 20,
			speed: 300,
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			navigation: {
				nextEl: swiperTarget + ' .swiper-button-next',
				prevEl: swiperTarget + ' .swiper-button-prev',
			},
			pagination: {
				el: swiperTarget + ' .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			on: {
				init: function (swiper) {
					swiper.slides.each(function (item, index) {
						if ($(item).find('[data-video]').length) {
							yt_init($(item).find('.media-embed'), index);
						}
					})
				},
				slideChange: function (swiper) {
					let currentKey = swiper.activeIndex;
					let currentSlide = swiper.slides[currentKey];

					if ($(currentSlide).find('[data-video]').length) {
						yt_init($(currentSlide).find('.media-embed'), currentKey);
					}
				}
			}
		});
	}

	window.addEventListener('load', buildKvCarousel);

	// ---------------------------------------------------------------

	// ---------------------------------------------------------------
	// Pleasure
	let featureSwiper = null;

	function buildFeatureCarousel(swiperId) {
		const swiperEl = swiperId + ' .swiper-container';
		var lazyLoadInstance = new LazyLoad();

		featureSwiper = new Swiper(swiperEl, {
			// allowTouchMove: false,
			slidesPerView: 'auto',
			spaceBetween: 20,
			navigation: {
				nextEl: swiperId + ' .swiper-button-next',
				prevEl: swiperId + ' .swiper-button-prev',
			},
			breakpoints: {
				1200: {
					spaceBetween: 40,
				},
			},
		});
	}

	// window.addEventListener('load', buildFeatureCarousel('#pleasure-swiper-panel1'));

	$('#pleasure-tabs')
		// onLoad
		.on('init.zf.tabs', function () {
			buildFeatureCarousel('#pleasure-swiper-panel1');
		})
		// onChange
		.on('change.zf.tabs', function (event, tab) {
			const panelId = tab.children('a').attr('href');
			const swiperId = $(panelId).find('.pleasure-swiper').attr('id');
			const $swiper = $('#' + swiperId).find('.swiper-container.swiper-container-initialized');

			if (!$swiper.length) {
				buildFeatureCarousel('#' + swiperId);
			}
		});

	// ---------------------------------------------------------------

	// ---------------------------------------------------------------
	// SAKURA story
	let storySwiper = null;

	function buildStoryCarousel(swiperId) {
		const swiperEl = swiperId + ' .swiper-container';

		storySwiper = new Swiper(swiperEl, {
			// allowTouchMove: false,
			speed: 500,
			loop: true,
			// slideToClickedSlide: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false
			},
			centeredSlides: true,
			slidesPerView: 'auto',
			spaceBetween: 12,
			observer: true,
			observeParents: true,
			navigation: {
				nextEl: swiperId + ' .swiper-button-next',
				prevEl: swiperId + ' .swiper-button-prev',
			},
			lazy: {
				loadPrevNext: true,
				loadPrevNextAmount: 2,
			},
			breakpoints: {
				767: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
			},
		});
	}

	// onLoad
	$('#story-tabs').on('init.zf.tabs', function () {
		buildStoryCarousel('#story-swiper-panel1');
	});

	// onChange
	$('#story-tabs').on('change.zf.tabs', function (event, tab) {
		const panelId = tab.children('a').attr('href');
		const swiperId = $(panelId).find('.story-swiper').attr('id');
		const $swiper = $('#' + swiperId).find('.swiper-container.swiper-container-initialized');

		if (!$swiper.length) {
			buildStoryCarousel('#' + swiperId);
		}
	});
	// ---------------------------------------------------------------
})();
